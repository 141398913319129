<template>
  <b-container>
    <h1 v-if="error.statusCode === 404">
      {{ pageNotFound }}
    </h1>
    <h1 v-else>
      {{ otherError }}
    </h1>
    <b-button variant="primary" to="/" class="location-icon">
      <i class="fa fa-home fa-xl" /><span class="icon-label">Home Page</span>
    </b-button>
    <b-button v-if="typeof $route !== 'undefined'" variant="primary" class="location-icon" href="javascript:window.location.href=window.location.href">
      <i class="fa fa-refresh fa-xl" /><span class="icon-label">{{ tryAgain }}</span>
    </b-button>
  </b-container>
</template>

<script>
export default {
  layout: 'redbag',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'There was an issue with your request.',
      tryAgain: 'Try again'
    }
  },
  head () {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 20px;
}
.icon-label{
    padding-left: 0.5em;
}
</style>
