export const state = () => ({
  setupCode: null,
  setupOrganizationName: null
})

export const mutations = {
  removeSetupCode (state, data) {
    state.setupCode = null
    state.setupOrganizationName = null
  },
  setSetupCode (state, data) {
    state.setupCode = data
  },
  setSetupOptions (state, data) {
    if (data.setupCode != null) {
      state.setupCode = data.setupCode
      state.setupOrganizationName = data.organizationName
    }
  }
}

export const getters = {
  setupCode (state) {
    return state.setupCode
  },
  setupOrganizationName (state) {
    return state.setupOrganizationName
  }
}

export const actions = {
  async setSetupCode ({ commit }, setupCode) {
    await commit('setSetupCode', setupCode)
  },
  async removeSetupCode ({ commit }) {
    await commit('removeSetupCode')
  },
  async setSetupOptions ({ commit }, setupOptions) {
    await commit('setSetupOptions', setupOptions)
  }
}
