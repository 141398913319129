import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _60a36cfe = () => interopDefault(import('../pages/chat.vue' /* webpackChunkName: "pages/chat" */))
const _d5f3e002 = () => interopDefault(import('../pages/enter.vue' /* webpackChunkName: "pages/enter" */))
const _91ccdd7e = () => interopDefault(import('../pages/external-chat.vue' /* webpackChunkName: "pages/external-chat" */))
const _4c608d60 = () => interopDefault(import('../pages/oidc-callback.vue' /* webpackChunkName: "pages/oidc-callback" */))
const _cf4e704a = () => interopDefault(import('../pages/oidc-callback-error.vue' /* webpackChunkName: "pages/oidc-callback-error" */))
const _b1940bba = () => interopDefault(import('../pages/qr-codes.vue' /* webpackChunkName: "pages/qr-codes" */))
const _4c387a7a = () => interopDefault(import('../pages/redbag/index.vue' /* webpackChunkName: "pages/redbag/index" */))
const _74b8ba53 = () => interopDefault(import('../pages/redbags-overview.vue' /* webpackChunkName: "pages/redbags-overview" */))
const _528ce00c = () => interopDefault(import('../pages/safety-audits.vue' /* webpackChunkName: "pages/safety-audits" */))
const _78d699b8 = () => interopDefault(import('../pages/silent-renew-oidc.vue' /* webpackChunkName: "pages/silent-renew-oidc" */))
const _213ef803 = () => interopDefault(import('../pages/sites-overview.vue' /* webpackChunkName: "pages/sites-overview" */))
const _2b1501d2 = () => interopDefault(import('../pages/incident/configure.vue' /* webpackChunkName: "pages/incident/configure" */))
const _3f3be585 = () => interopDefault(import('../pages/incident/help.vue' /* webpackChunkName: "pages/incident/help" */))
const _82f85f3c = () => interopDefault(import('../pages/incident/list.vue' /* webpackChunkName: "pages/incident/list" */))
const _42bbe164 = () => interopDefault(import('../pages/incident/start.vue' /* webpackChunkName: "pages/incident/start" */))
const _28aa97a0 = () => interopDefault(import('../pages/rbim/list.vue' /* webpackChunkName: "pages/rbim/list" */))
const _8ba83004 = () => interopDefault(import('../pages/redbag/about.vue' /* webpackChunkName: "pages/redbag/about" */))
const _70437c29 = () => interopDefault(import('../pages/redbag/edit.vue' /* webpackChunkName: "pages/redbag/edit" */))
const _66e606ee = () => interopDefault(import('../pages/redbag/setup.vue' /* webpackChunkName: "pages/redbag/setup" */))
const _382169be = () => interopDefault(import('../pages/redbag/site-bag.vue' /* webpackChunkName: "pages/redbag/site-bag" */))
const _30fd00a6 = () => interopDefault(import('../pages/rbim/_serialNumber/index.vue' /* webpackChunkName: "pages/rbim/_serialNumber/index" */))
const _3e86cce3 = () => interopDefault(import('../pages/redbag/_barcode/index.vue' /* webpackChunkName: "pages/redbag/_barcode/index" */))
const _0c4e01de = () => interopDefault(import('../pages/site/_id/index.vue' /* webpackChunkName: "pages/site/_id/index" */))
const _48015040 = () => interopDefault(import('../pages/incident/_id/after-action.vue' /* webpackChunkName: "pages/incident/_id/after-action" */))
const _32f7680f = () => interopDefault(import('../pages/incident/_id/clear-rooms.vue' /* webpackChunkName: "pages/incident/_id/clear-rooms" */))
const _6c6219f2 = () => interopDefault(import('../pages/incident/_id/overview.vue' /* webpackChunkName: "pages/incident/_id/overview" */))
const _1ecef21e = () => interopDefault(import('../pages/redbag/_barcode/about.vue' /* webpackChunkName: "pages/redbag/_barcode/about" */))
const _6ab40799 = () => interopDefault(import('../pages/redbag/_barcode/contents.vue' /* webpackChunkName: "pages/redbag/_barcode/contents" */))
const _3c083aa7 = () => interopDefault(import('../pages/redbag/_barcode/generate-barcode.vue' /* webpackChunkName: "pages/redbag/_barcode/generate-barcode" */))
const _718083ed = () => interopDefault(import('../pages/redbag/_barcode/inventory.vue' /* webpackChunkName: "pages/redbag/_barcode/inventory" */))
const _677f9168 = () => interopDefault(import('../pages/redbag/_barcode/lockdown.vue' /* webpackChunkName: "pages/redbag/_barcode/lockdown" */))
const _7b49b81f = () => interopDefault(import('../pages/redbag/_barcode/remedy.vue' /* webpackChunkName: "pages/redbag/_barcode/remedy" */))
const _68eddde4 = () => interopDefault(import('../pages/redbag/_barcode/setup.vue' /* webpackChunkName: "pages/redbag/_barcode/setup" */))
const _23427930 = () => interopDefault(import('../pages/site/_id/inventory.vue' /* webpackChunkName: "pages/site/_id/inventory" */))
const _18133928 = () => interopDefault(import('../pages/site/_id/task/index.vue' /* webpackChunkName: "pages/site/_id/task/index" */))
const _9aeb565c = () => interopDefault(import('../pages/site/_id/task/create.vue' /* webpackChunkName: "pages/site/_id/task/create" */))
const _5f7479d0 = () => interopDefault(import('../pages/site/_id/task/_taskId/edit.vue' /* webpackChunkName: "pages/site/_id/task/_taskId/edit" */))
const _cfeeb60e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/chat",
    component: _60a36cfe,
    name: "chat"
  }, {
    path: "/enter",
    component: _d5f3e002,
    name: "enter"
  }, {
    path: "/external-chat",
    component: _91ccdd7e,
    name: "external-chat"
  }, {
    path: "/oidc-callback",
    component: _4c608d60,
    name: "oidc-callback"
  }, {
    path: "/oidc-callback-error",
    component: _cf4e704a,
    name: "oidc-callback-error"
  }, {
    path: "/qr-codes",
    component: _b1940bba,
    name: "qr-codes"
  }, {
    path: "/redbag",
    component: _4c387a7a,
    name: "redbag"
  }, {
    path: "/redbags-overview",
    component: _74b8ba53,
    name: "redbags-overview"
  }, {
    path: "/safety-audits",
    component: _528ce00c,
    name: "safety-audits"
  }, {
    path: "/silent-renew-oidc",
    component: _78d699b8,
    name: "silent-renew-oidc"
  }, {
    path: "/sites-overview",
    component: _213ef803,
    name: "sites-overview"
  }, {
    path: "/incident/configure",
    component: _2b1501d2,
    name: "incident-configure"
  }, {
    path: "/incident/help",
    component: _3f3be585,
    name: "incident-help"
  }, {
    path: "/incident/list",
    component: _82f85f3c,
    name: "incident-list"
  }, {
    path: "/incident/start",
    component: _42bbe164,
    name: "incident-start"
  }, {
    path: "/rbim/list",
    component: _28aa97a0,
    name: "rbim-list"
  }, {
    path: "/redbag/about",
    component: _8ba83004,
    name: "redbag-about"
  }, {
    path: "/redbag/edit",
    component: _70437c29,
    name: "redbag-edit"
  }, {
    path: "/redbag/setup",
    component: _66e606ee,
    name: "redbag-setup"
  }, {
    path: "/redbag/site-bag",
    component: _382169be,
    name: "redbag-site-bag"
  }, {
    path: "/rbim/:serialNumber",
    component: _30fd00a6,
    name: "rbim-serialNumber"
  }, {
    path: "/redbag/:barcode",
    component: _3e86cce3,
    name: "redbag-barcode"
  }, {
    path: "/site/:id",
    component: _0c4e01de,
    name: "site-id"
  }, {
    path: "/incident/:id?/after-action",
    component: _48015040,
    name: "incident-id-after-action"
  }, {
    path: "/incident/:id?/clear-rooms",
    component: _32f7680f,
    name: "incident-id-clear-rooms"
  }, {
    path: "/incident/:id?/overview",
    component: _6c6219f2,
    name: "incident-id-overview"
  }, {
    path: "/redbag/:barcode/about",
    component: _1ecef21e,
    name: "redbag-barcode-about"
  }, {
    path: "/redbag/:barcode/contents",
    component: _6ab40799,
    name: "redbag-barcode-contents"
  }, {
    path: "/redbag/:barcode/generate-barcode",
    component: _3c083aa7,
    name: "redbag-barcode-generate-barcode"
  }, {
    path: "/redbag/:barcode/inventory",
    component: _718083ed,
    name: "redbag-barcode-inventory"
  }, {
    path: "/redbag/:barcode/lockdown",
    component: _677f9168,
    name: "redbag-barcode-lockdown"
  }, {
    path: "/redbag/:barcode/remedy",
    component: _7b49b81f,
    name: "redbag-barcode-remedy"
  }, {
    path: "/redbag/:barcode/setup",
    component: _68eddde4,
    name: "redbag-barcode-setup"
  }, {
    path: "/site/:id?/inventory",
    component: _23427930,
    name: "site-id-inventory"
  }, {
    path: "/site/:id?/task",
    component: _18133928,
    name: "site-id-task"
  }, {
    path: "/site/:id?/task/create",
    component: _9aeb565c,
    name: "site-id-task-create"
  }, {
    path: "/site/:id?/task/:taskId/edit",
    component: _5f7479d0,
    name: "site-id-task-taskId-edit"
  }, {
    path: "/",
    component: _cfeeb60e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
