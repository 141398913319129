export const oidcSettings = {
  authority: process.env.NUXT_ENV_IDENTITY_URL,
  clientId: 'redbag.classroom', // 'efc0b23e-5f7e-4ba8-9f13-64718b9aea5b', // 'redbag.classroom',
  redirectUri: `${process.env.NUXT_ENV_BASE_URL}/oidc-callback`,
  responseType: 'code', // 'code',
  scope: 'openid profile fibbot offline_access roles',
  // scope: 'openid profile offline_access',
  automaticSilentRenew: true,
  silentRedirectUri: `${process.env.NUXT_ENV_BASE_URL}/silent-renew-oidc`,
  post_logout_redirect_uri: `${process.env.NUXT_ENV_BASE_URL}`,
  extraTokenParams: { test: 'test' }
}
