<template>
  <div>
    <b-navbar v-if="!isHomePageRoute() || 1 == 1" style="height: auto !important;" fixed="top" toggleable="lg" class="navbar-static-top fixed-top">
      <b-navbar-brand :to="{ name: 'index'}">
        <img src="~/assets/images/RedBag_logo-white.svg?text=RedBag" alt="RedBag">
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto" right>
          <b-nav-item-dropdown
            v-if="oidcUser"
            id="manage-dropdown"
            text="Incidents"
            toggle-class="nav-link-custom"
            right
          >
            <b-dropdown-item :to="'/incident/start'">
              Start Incident
            </b-dropdown-item>
            <b-dropdown-item :to="'/incident/list'">
              View Incidents
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            v-if="oidcUser"
            id="manage-dropdown"
            text="RedBags"
            toggle-class="nav-link-custom"
            right
          >
            <b-dropdown-item :to="'/sites-overview'">
              View Sites
            </b-dropdown-item>
            <b-dropdown-item :to="'/safety-audits'">
              Safety Audit Summary
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            v-if="oidcUser"
            id="manage-dropdown"
            text="Configure"
            toggle-class="nav-link-custom"
            right
          >
            <b-dropdown-item :to="'/incident/configure'">
              Incident Surveys
            </b-dropdown-item>
            <b-dropdown-item :to="'/rbim/list'">
              View RBIMs
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-if="oidcUser" @click="confirmSignout">
            Sign out {{ oidcUser.preferred_username }}
          </b-nav-item>
          <b-nav-item v-if="!oidcUser" @click="authenticateOidc($route.params.returnUrl)">
            Sign in
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-jumbotron v-if="$route.name == 'redbag-barcode-lockdown'" class="jumbotron" style="top:0px;background-color:#C73D3D;border-radius: 0 !important;margin-bottom:0;">
      <nuxt-link :to="{ name: 'redbag-barcode-lockdown', params: {barcode: barcode}}" role="button" style="text-decoration:none;">
        <div class="jumbotron" style="background-color:white;align-content:center;text-align:center;">
          <img class="" src="~/assets/images/lockdown.svg" alt="Lockdown">
          <div style="font-size:xx-large;color:#6588B1;text-decoration:none;">
            Lockdown
          </div>
        </div>
      </nuxt-link>
    </b-jumbotron>
    <IncidentBanner v-if="isHomePageRoute() || isIncidentListRoute()" />
    <div>
      <nuxt />
      <div v-if="isHomePageRoute()" style="text-align:center;padding-bottom:1em;margin-top:2rem;">
        <b-button variant="danger" style="text-align:center;" @click="gotoAboutRedBagPage">
          Learn More About RedBag
        </b-button>
      </div>
      <PartnersCarousel v-if="1 == 2" />
    </div>
  </div>
</template>

<script>
// import BroadcastMessage from '~/components/BroadcastMessage.vue'
import { mapGetters, mapActions } from 'vuex'
import PartnersCarousel from '~/components/PartnersCarousel.vue'
import IncidentBanner from '~/components/incident/IncidentBanner.vue'

export default {
  components: {
    // BroadcastMessage,
    PartnersCarousel,
    IncidentBanner
  },
  data () {
    return {
      slide: 0,
      sliding: null,
      barcode: this.$route.params.barcode
    }
  },
  head () {
    return {
      bodyAttrs: {
        class: this.isHomePageRoute() ? 'dashboard-body' : 'test',
        style: 'padding-top: 90px;'
      }
    }
  },
  computed: {
    ...mapGetters('oidc', [
      'oidcUser'
    ])
  },
  methods: {
    confirmSignout () {
      const sure = confirm('Are you sure you want to sign out?')
      if (sure) {
        this.signOutOidc()
      }
    },
    onSlideStart (slide) {
      this.sliding = true
    },
    isAboutPage () {
      // alert(this.$route.name)
      return (this.$route.name === 'redbag-barcode-about' || this.$route.name === 'redbag-about')
    },
    isHomePageRoute () {
      // alert(this.$route.name)
      return (this.$route.name === 'index')
    },
    isIncidentListRoute () {
      // alert(this.$route.name)
      return (this.$route.name === 'incident-list')
    },
    onSlideEnd (slide) {
      this.sliding = false
    },
    gotoAboutRedBagPage () {
      // this.$router.push('/redbag/about')
      // this.$router.push('https://getredbag.com/')
      window.location.href = 'https://getredbag.com/'
    },
    ...mapActions('oidc', [
      'signOutOidc',
      'authenticateOidc'
    ])
  }
}
</script>

<style>

.navbar-logo{
  width:200px;
  height: 80px;
}

.back-button{
    display: flex;
    height: 40px;
}
/*
    Our blue:   #6588B1
    Our red:    #C73D3D
*/
/* Custom Css */
body {
    padding-top: 80px;
}

body.dashboard-body {
    padding-top: 0px;
}

.card-header-danger {
    background-color: #C73D3D !important;
    background: linear-gradient(60deg,#C73D3D,#C73D3D) !important;
    /*background: linear-gradient(60deg,#ef5350,#C73D3D);*/
}

.card-header-primary {
    background: linear-gradient(60deg,#436996,#6588b1) !important;
}

.carousel-inner img {
    margin: auto;
}

.btn-danger {
    background-color: #C73D3D;
}

.redbagTitle {
    font-weight: bold;
    color: #C73D3D;
}
.abs-center-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.redbagHeader{
    font-weight:bold;
    color:white;
    font-family: Calibri, sans-serif;
    font-size:xx-large;
    margin-left:10px;
    text-align:center;
}

.navbar {
    background-color: #C73D3D;
    text-align: center;
}

.navbar-light .navbar-nav .nav-link{
  color:white;
}

.navbar-redbag {
    background-color: #C73D3D;
    /*background: linear-gradient(60deg,#ef5350,#C73D3D) !important;*/
    text-align: center;
    height: 80px;
    color:white;
}

.hidden{
    display:none;
}

.notificationAnnouncement {
    background-color: #6588B1;
    padding:1rem;
    color:white;
    text-align:center;
    margin-bottom: 2rem;
}
/*
#broadcastMessage {
    background-color: #e9ecef;
    border-radius: 1rem 1rem 1rem 0rem;
    padding: 1rem;
}
*/
#broadcastContainer{
    margin-bottom:2rem;
}

.sponsor-container{
    height:100px;
}

.sponsor-container img {
    max-height:100px;
    max-width:100%;
}
        /*
.sponsor-container img {
    display: block;
    height: 100%;
    width: auto;
}
    */

#contact-us-overlay {
    color: #333;
    position: fixed;
    right: 10px;
    bottom: 0px;
    z-index: 1001;
}
    #contact-us-overlay a {
        color: #333;
        font-weight: bold;
    }

    #contact-us-overlay ul {
        margin: 40px 0px 0px 40px;
        padding: 0px;
        list-style: none;
    }

#contact-us-overlay li {
    float: left;
    margin-left: 8px;
    color: #333;
    background-color: #333;
    padding: 4px 4px 0px 4px;
    -webkit-border-radius: 10px 10px 0px 0px;
    -moz-border-radius: 10px 10px 0px 0px;
    border-radius: 10px 10px 0px 0px;
}

.contact-us-main .contact-us-inner {
    padding-left: 10px;
    font-size:x-large;
    background-image: none;
    background-position: 6px 7px;
    background-repeat: no-repeat;
}

.contact-us-inner {
    background-color: white;
    padding: 5px 8px 4px 8px;
    -webkit-border-radius: 8px 8px 0px 0px;
    -moz-border-radius: 8px 8px 0px 0px;
    border-radius: 8px 8px 0px 0px;
}
</style>

<!--<template>
  <v-app dark>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      fixed
      app
    >
      <v-list>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          router
          exact
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="clipped"
      fixed
      app
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-btn
        @click.stop="miniVariant = !miniVariant"
        icon
      >
        <v-icon>mdi-{{ `chevron-${miniVariant ? 'right' : 'left'}` }}</v-icon>
      </v-btn>
      <v-btn
        @click.stop="clipped = !clipped"
        icon
      >
        <v-icon>mdi-application</v-icon>
      </v-btn>
      <v-btn
        @click.stop="fixed = !fixed"
        icon
      >
        <v-icon>mdi-minus</v-icon>
      </v-btn>
      <v-toolbar-title v-text="title" />
      <v-spacer />
      <v-btn
        @click.stop="rightDrawer = !rightDrawer"
        icon
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
    <v-content>
      <v-container>
        <nuxt />
      </v-container>
    </v-content>
    <v-navigation-drawer
      v-model="rightDrawer"
      :right="right"
      temporary
      fixed
    >
      <v-list>
        <v-list-item @click.native="right = !right">
          <v-list-item-action>
            <v-icon light>
              mdi-repeat
            </v-icon>
          </v-list-item-action>
          <v-list-item-title>Switch drawer (click me)</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-footer
      :fixed="fixed"
      app
    >
      <span>&copy; 2019</span>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data () {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: 'mdi-apps',
          title: 'Welcome',
          to: '/'
        },
        {
          icon: 'mdi-chart-bubble',
          title: 'Inspire',
          to: '/inspire'
        }
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'Vuetify.js'
    }
  }
}
</script>
-->
