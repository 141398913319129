import Vue from 'vue'
import VueGtag from 'vue-gtag'

/**
 * @type {import('@nuxt/types').Plugin}
 */
const vueGtag = ({ app, env }) => {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.NUXT_ENV_GOOGLE_ANALYTICS },
      disabled: true // Here you could ignore user privacy and set to true
    },
    app.router
  )
}
export default vueGtag

/*

Vue.use(VueGtag, {
  config: { id: " },
  disabled: true,
  },
  App.router
);

new Vue({
  render: h => h(App)
}).$mount("#app")
*/
