<template>
  <b-container v-if="activeIncident && activeIncident !== ''" id="activeIncidentContainer" fluid style="">
    <div class="row notificationAnnouncement">
      <span class="mx-auto" style="font-size:xx-large">Active Incident!</span>
    </div>
    <b-row>
      <b-col cols="2">
        <img src="~/assets/images/icons/alert-triangle-colored.svg">
      </b-col>
      <b-col cols="10">
        <div v-if="activeIncidentCount > 0" id="activeIncident" class="" role="alert">
          <div
            v-for="incident in activeIncident"
            :key="incident.incidentId"
          >
            <nuxt-link
              :to="{ name: 'incident-id-overview', params: {id: incident.incidentId}}"
              role="button"
              style="text-decoration:none;"
            >
              Click to go to active incident dashboard for {{ incident.incidentStart.locationDescription }}
            </nuxt-link>
          </div>
        </div>
        <div id="activeIncidentTimestamp" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// import { mapGetters, mapMutations } from 'vuex'
// import { Store } from 'vuex'

// , mapState
export default {
  name: 'IncidentBanner',
  // props: ['incidentMessage'],
  components: {
  },
  data () {
    return {
      visibilityChange: '',
      timer: null,
      timerDelay: 4000,
      timerCount: 0,
      activeIncident: null,
      destroyed: false
    }
  },
  computed: {
    // ...mapGetters({ incidentMessage: 'IncidentBanner/getIncidentBanner' })
    /*
    ...mapState({
      incidentMessage: (state) => {
        // return 'test'
        return state.incidentMessage
      }
    })
    */
    activeIncidentCount () {
      return (this.activeIncident != null && this.activeIncident.length && this.activeIncident.length > 0) ? this.activeIncident.length : 0
    }
  },
  mounted () {
    console.log('mounted')
    this.setVisibilityChange()
    this.setRepeatedTimer()
  },
  beforeDestroy () {
    this.destroyed = true
  },
  methods: {
    // ...mapMutations({ getIncidentBanner: 'IncidentBanner/get' }),
    isPageVisible () {
      return !(document[this.hidden])
    },
    setRepeatedTimer () {
      setTimeout(async function () {
        if (this.destroyed) {
          return
        }
        this.timerCount += 1

        // Set delay so we can use backoff
        this.setTimerDelay()

        // Check status of Bag
        if (this.isPageVisible()) {
          await this.checkForActiveIncidents()
        } else {
          console.log('Stopping the request because we\'re not looking at the app')
        }

        // Call our timer again
        this.setRepeatedTimer()
      }.bind(this), this.timerDelay)
    },
    setTimerDelay () {
      if (this.$route.params.barcode == null) {
        this.timerDelay = 3500
      } else {
        this.timerDelay = 3500
      }
    },
    async checkForActiveIncidents () {
      const result = await this.$axios.get(`${process.env.NUXT_ENV_CLOUD_API_URL}/GetIncidentDetails?IncidentStatus=Active`).catch((error) => {
        console.log(error)
      })
      console.log(result.data)
      if (result.data && result.data.length >= 1) {
        this.activeIncident = result.data // result.data[0].incidentId
      }
    },
    setVisibilityChange () {
      // https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
      if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
        this.hidden = 'hidden'
        this.visibilityChange = 'visibilitychange'
      } else if (typeof document.msHidden !== 'undefined') {
        this.hidden = 'msHidden'
        this.visibilityChange = 'msvisibilitychange'
      } else if (typeof document.webkitHidden !== 'undefined') {
        this.hidden = 'webkitHidden'
        this.visibilityChange = 'webkitvisibilitychange'
      }
      // alert(`Hidden: ${this.hidden}, changetype: ${this.visibilityChange}`)
    }
  }
}
</script>
<style scoped>
#activeIncident {
  background-color: #e9ecef;
  border-radius: 1rem 1rem 1rem 0;
  padding: 1rem;
}
</style>
