<template>
  <div>
    <div style="text-align:center;padding-bottom:1em;">
      <span style="text-transform:uppercase;color:#6588B1">We are proud to partner with:</span>
    </div>
    <b-carousel
      id="carousel-sponsors"
      v-model="slide"
      :interval="10000"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- id="carousel-sponsors" class="carousel slide" data-interval="10000" data-ride="carousel"> -->
      <!-- Wrapper for slides -->
      <div class="carousel-inner">
        <div class="carousel-item sponsor-container">
          <img src="~/assets/images/sponsors/CCSD.jpg" class="d-block " alt="Cherry Creek Schools">
          <div class="carousel-caption" />
        </div>
        <div class="carousel-item active sponsor-container">
          <img src="~/assets/images/Galibots_Logo.jpg" height="80" class="d-block" alt="Galibots">
          <div class="carousel-caption" />
        </div>
        <div class="carousel-item sponsor-container">
          <img src="~/assets/images/AED_EverywhereLogo-name_only.jpg" class="d-block " alt="Denver Health">
          <div class="carousel-caption" />
        </div>
        <!--
              <div class="carousel-item sponsor-container">
                  <img src="~/assets/images/sponsors/HealthOne.png" class="d-block" alt="Health One">
                  <div class="carousel-caption">
                  </div>
              </div>
              <div class="carousel-item sponsor-container">
                  <img src="~/assets/images/sponsors/CCSD.jpg" class="d-block " alt="Cherry Creek Schools">
                  <div class="carousel-caption">
                  </div>
              </div>
              <div class="carousel-item sponsor-container">
                  <img src="~/assets/images/sponsors/Centura.jpg" class="d-block " alt="Centura">
                  <div class="carousel-caption">

                  </div>
              </div>
              <div class="carousel-item sponsor-container">
                  <img src="~/assets/images/sponsors/Concentra.jpg" class="d-block" alt="Concentra">
                  <div class="carousel-caption">
                  </div>
              </div>
              <div class="carousel-item sponsor-container">
                  <img src="~/assets/images/sponsors/UCHealth.png" class="d-block " alt="UC Health">
                  <div class="carousel-caption">
                  </div>
              </div>
              -->
      </div>
    </b-carousel>
  </div>
</template>

<script>

export default {
  name: 'PartnersCarousel',
  components: {
  },
  data () {
    return {
      slide: 0,
      sliding: null
    }
  },
  methods: {
    onSlideStart (slide) {
      this.sliding = true
    },
    isHomePageRoute () {
      // alert(this.$route.name)
      return (this.$route.name === 'redbag-barcode' || this.$route.name === 'redbags-overview')
    },
    onSlideEnd (slide) {
      this.sliding = false
    }
  }
}
</script>
