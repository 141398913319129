<template>
  <span>
    <i class="fa fa-history fa-lg pull-right" @click="updateAndShowBroadcasts()" /><span class="icon-label" />
    <b-modal
      id="classroom-broadcast-history-modal"
      v-model="showModal"
      :body-class="['no-top-padding', 'modal-body']"
      title="Broadcast History"
      style="padding:0 0 0 0;"
      size="lg"
      ok-only
      hide-footer
      @ok="handleOkButton"
      @shown="scrollToMessageListBottom"
    >
      <template #modal-title>
        {{ showAllBroadcasts == true ? 'Broadcast History' : 'Room Messaging History' }}
      </template>
      <template #modal-header-close>
        <i class="fa fa-chevron-down" /><span class="icon-label" />
      </template>
      <div v-if="loading" class="d-flex justify-content-center mb-3">
        <b-spinner label="Loading..." />
      </div>
      <b-card>
        <b-card-text>
          <div
            v-for="broadcast in filteredBroadcasts"
            :key="broadcast.sentOn"
            :class="getBroadcastType(broadcast.type)"
            class=""
          >
            <!--  d-flex justify-content-between align-items-center -->
            <div style="margin-bottom:0;">
              {{ broadcast.message }}
            </div>
            <div style="margin-top:0;margin-bottom:0.5rem;">
              <span style="font-size:80%;font-weight:400;color:#6588B1;">
                <span v-show="showAllBroadcasts && showRoomBroadcasts">To {{ broadcast.type == 1 ? `Select Rooms` : 'Everyone' }}</span>
                {{ moment(broadcast.sentOn).format('h:mm:ss a') }}{{ (broadcast.sentByName != null && broadcast.sentByName != '' ? ' - ' + broadcast.sentByName : '' ) }}
              </span></div>
            <div v-if="broadcast.type == 3">
              <b-button v-b-toggle.collapse-1-inner size="sm">View Rooms</b-button>
              <b-collapse id="collapse-1-inner" class="mt-2">
                <b-card
                  v-for="room in broadcast.redBagGuids"
                  :key="room"
                  style="color:black;"
                >
                  {{ room }}
                </b-card>
              </b-collapse>
            </div>
          </div>
        </b-card-text>
      </b-card>
    </b-modal>
  </span>
</template>

<script>
// import _ from 'lodash'
import { mapGetters } from 'vuex'
const moment = require('moment')

export default {
  name: 'ClassroomBroadcastHistory',
  mixins: [
  ],
  layout: 'manage',
  props: ['userId', 'incidentId', 'bagGuid', 'showRoomBroadcasts', 'showAllBroadcasts'],
  data () {
    return {
      tryScrollDown: false,
      broadcasts: [],
      moment,
      loading: true,
      showModal: false,
      newRoomBroadcastMessage: null,
      newBroadcastMessage: null
    }
  },
  computed: {
    filteredBroadcasts () {
      return this.broadcasts.filter(b => (b.type === 1 && this.showRoomBroadcasts) || (b.type === 0 && this.showAllBroadcasts))
    },
    ...mapGetters({
      broadcastMessage: 'BroadcastMessage/getBroadcastMessage',
      broadcastMessageDate: 'BroadcastMessage/getBroadcastMessageDate',
      broadcastMessageFrom: 'BroadcastMessage/getBroadcastMessageFrom',
      roomBroadcastMessage: 'BroadcastMessage/getRoomBroadcastMessage',
      roomBroadcastMessageDate: 'BroadcastMessage/getRoomBroadcastMessageDate',
      roomBroadcastMessageFrom: 'BroadcastMessage/getRoomBroadcastMessageFrom',
      incidentInstructions: 'BroadcastMessage/getIncidentInstructions',
      // incidentId: 'BroadcastMessage/getIncidentId',
      bagName: 'BroadcastMessage/getBagName'
    }),
    broadcastMessageTime () {
      return this.broadcastMessageDate == null ? '' : moment(this.broadcastMessageDate).format('h:mm:ss a')
    },
    roomBroadcastMessageTime () {
      return this.roomBroadcastMessageDate == null ? '' : moment(this.roomBroadcastMessageDate).format('h:mm:ss a')
    },
    identity () {
      return `${this.incidentId}-${this.bagGuid}-${this.userId}`
    },
    roomUserName () {
      return `Room (${this.bagName})`
    }
  },
  watch: {
    broadcastMessage () {
      this.newBroadcastMessage = true
      this.showModal = false
      try {
        if (window.navigator) {
          window.navigator.vibrate(300)
        }
      } catch (error) {
        console.log('Unable to vibrate')
        console.log(error)
      }
      setTimeout(() => {
        this.newBroadcastMessage = false
      }, 15000)
    },
    roomBroadcastMessage (newRoomBroadcastMessage) {
      this.newRoomBroadcastMessage = true
      this.showModal = false
      try {
        if (window.navigator) {
          window.navigator.vibrate(300)
        }
      } catch (error) {
        console.log('Unable to vibrate')
        console.log(error)
      }
      setTimeout(() => {
        this.newRoomBroadcastMessage = false
      }, 15000)
    }
  },
  async mounted () {
    /* if (this.showMessages) {
      await this.updateAndShowBroadcasts()
    } */
  },
  updated () {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been re-rendered
      if (this.tryScrollDown) {
        this.tryScrollDown = false
        this.scrollToMessageListBottom()
      }
    })
  },
  methods: {
    async updateAndShowBroadcasts () {
      console.log('Getting broadcast messages')
      this.loading = true
      this.showModal = true
      const messages = await this.getBroadcasts()
      this.broadcasts = messages
      this.loading = false
    },
    async silentUpdateBroadcasts () {
      console.log('Getting broadcast messages')
      this.loading = true
      // this.showModal = true
      const messages = await this.getBroadcasts()
      this.broadcasts = messages
      this.loading = false
    },
    getBroadcastType (item) {
      switch (item) {
        case 0:
          return 'all-rooms-broadcast'
        case 1:
          return 'specific-room-broadcast'
        default:
          return ''
      }
    },
    getBroadcastEnumType (item) {
      switch (item) {
        case 'success':
          return 0
        case 'warning':
          return 2
        case 'danger':
          return 3
        case 'light':
          return 1
        default:
          return 0
      }
    },
    logMessage (message) {
      console.log('MESSAGE!')
      console.log(message)
      return 'Ok'
    },
    async getBroadcasts () {
      // const vm = this
      const response = await this.$axios.get(`${process.env.NUXT_ENV_CLOUD_API_URL}/GetBagBroadcasts?RedBagGuid=${this.bagGuid}`)
      return response.data
    },
    handleOkButton (bvModalEvt) {
      // this.showMessages = false
      // bvModalEvt.preventDefault()
      // this.handleInputTextKeypress()
    },
    scrollToMessageListBottom () {
    }
  }
}
</script>

<style scoped>
  /deep/ .modal-body{
    padding:0;
    display:flex;
    flex-direction: column;
    background-color: #f9f9f7;
  }
  /deep/ .modal-header{
    background-color:#C73D3D;
    color:white;
  }
  /deep/ .modal-content{
    border: 0;
    border-radius: 0%;
    background-color: #f9f9f7;
  }
  /deep/ .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
  }

  /*
  .all-rooms-broadcast {
    color: white;
    background-color:#6588B1;
  }
  .specific-room-broadcast {
    color: white;
    background-color:#ADADAD;
  } */
</style>
