export const state = () => ({
  tenantId: null,
  tenantName: null
})

export const mutations = {
  removetenantId (state, data) {
    state.tenantId = null
    state.tenantName = null
  },
  settenantId (state, data) {
    // dating state to ${data}`)
    state.tenantId = data
  },
  setSetupOptions (state, data) {
    if (data.tenantId != null) {
      state.tenantId = data.tenantId
      state.tenantName = data.tenantName
    }
  }
}

export const getters = {
  /*
  tenantId (state) {
    return state.tenantId
  },
  tenantName (state) {
    return state.tenantName
  }
  */
}

export const actions = {
  /*
  async setTenantId ({ commit }, tenantId) {
    await commit('settenantId', tenantId)
  },
  async removeTenantId ({ commit }) {
    await commit('removetenantId')
  },
  async setTenantOptions ({ commit }, setupOptions) {
    await commit('setSetupOptions', setupOptions)
  } */
}
