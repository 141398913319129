export default function ({ $axios, store }) {
  $axios.onRequest((config) => {
    if (store.state.oidc.access_token != null) {
      config.headers.common['access-token'] = store.state.oidc.access_token
      config.headers.common.Authorization = `Bearer ${store.state.oidc.access_token}`
    } /*
    const tenantData = localStorage.getItem('vuex')
    let tenantDataJson = {}
    if (tenantData) {
      try {
        tenantDataJson = JSON.parse(tenantData)
      } catch (error) {
      }
    }
    const tenantId = store?.state?.oidc?.user?.tenant_id ?? tenantDataJson?.tenantInformation?.tenantId ?? store?.state?.tenantInformation?.tenantId ?? null
    */
    const tenantId = store?.state?.oidc?.user?.tenant_id ?? process.env.NUXT_ENV_TENANT_ID
    if (tenantId != null) {
      config.headers.common['tenant-id'] = tenantId
    }
  })
}
