<template>
  <b-container>
    <b-container>
      <BroadcastMessage />
      <nuxt />
    </b-container>
  </b-container>
</template>

<script>
import BroadcastMessage from '~/components/BroadcastMessage.vue'

export default {
  components: {
    BroadcastMessage
  }
}
</script>

<style>

/*
    Our blue:   #6588B1
    Our red:    #C73D3D
*/
/* Custom Css */
body {
    padding-top: 80px;
}

body.dashboard-body {
    padding-top: 0px;
}

.card-header-danger {
    background-color: #C73D3D !important;
    background: linear-gradient(60deg,#C73D3D,#C73D3D) !important;
    /*background: linear-gradient(60deg,#ef5350,#C73D3D);*/
}

.card-header-primary {
    background: linear-gradient(60deg,#436996,#6588b1) !important;
}

.carousel-inner img {
    margin: auto;
}

.btn-danger {
    background-color: #C73D3D;
}

.redbagTitle {
    font-weight: bold;
    color: #C73D3D;
}
.abs-center-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.redbagHeader{
    font-weight:bold;
    color:white;
    font-family: Calibri, sans-serif;
    font-size:xx-large;
    margin-left:10px;
    text-align:center;
}

.navbar {
    background-color: #C73D3D;
    text-align: center;
    height: 80px;
}

.navbar-redbag {
    background-color: #C73D3D;
    /*background: linear-gradient(60deg,#ef5350,#C73D3D) !important;*/
    text-align: center;
    height: 80px;
}

.hidden{
    display:none;
}

.notificationAnnouncement {
    background-color: #6588B1;
    padding:1rem;
    color:white;
    text-align:center;
    margin-bottom: 2rem;
}

#broadcastMessage {
    background-color: #e9ecef;
    border-radius: 1rem 1rem 1rem 0rem;
    padding: 1rem;
}

#broadcastContainer{
    margin-bottom:0rem;
}

.sponsor-container{
    height:100px;
}

.sponsor-container img {
    max-height:100px;
    max-width:100%;
}
        /*
.sponsor-container img {
    display: block;
    height: 100%;
    width: auto;
}
    */

#contact-us-overlay {
    color: #333;
    position: fixed;
    right: 10px;
    bottom: 0px;
    z-index: 1001;
}
    #contact-us-overlay a {
        color: #333;
        font-weight: bold;
    }

    #contact-us-overlay ul {
        margin: 40px 0px 0px 40px;
        padding: 0px;
        list-style: none;
    }

#contact-us-overlay li {
    float: left;
    margin-left: 8px;
    color: #333;
    background-color: #333;
    padding: 4px 4px 0px 4px;
    -webkit-border-radius: 10px 10px 0px 0px;
    -moz-border-radius: 10px 10px 0px 0px;
    border-radius: 10px 10px 0px 0px;
}

.contact-us-main .contact-us-inner {
    padding-left: 10px;
    font-size:x-large;
    background-image: none;
    background-position: 6px 7px;
    background-repeat: no-repeat;
}

.contact-us-inner {
    background-color: white;
    padding: 5px 8px 4px 8px;
    -webkit-border-radius: 8px 8px 0px 0px;
    -moz-border-radius: 8px 8px 0px 0px;
    border-radius: 8px 8px 0px 0px;
}
</style>
