<template>
  <b-container v-if="broadcastMessage && broadcastMessage !== ''" id="broadcastContainer" fluid style="" class="sticky-top">
    <div hidden class="row notificationAnnouncement">
      <span class="mx-auto" style="font-size:xx-large">Notification!</span>
    </div>
    <b-row class="notificationAnnouncement" style="margin-bottom:0">
      <b-col cols="2" style="padding-left:0;">
        <img :class="newBroadcastMessage ? 'blink-1' : ''" width="60px" src="~/assets/images/BroadcastNotification.png">
      </b-col>
      <b-col cols="10">
        <div style="d-flex justify-content-between align-items-center">
          <small>{{ broadcastMessageFrom }} To All • {{ broadcastMessageTime }}</small>
          <classroom-broadcast-history
            ref="broadcastHistoryComponent"
            :incident-id="incidentId"
            :bag-guid="barcode"
            :show-all-broadcasts="true"
            :show-room-broadcasts="false"
          />
        </div>
        <div id="broadcastMessage" class="" role="alert">
          {{ broadcastMessage }}
        </div>
        <div id="broadcastMessageTimestamp" hidden>
          {{ broadcastMessageTime }}
        </div>
      </b-col>
    </b-row>
    <b-row v-show="roomBroadcastMessage && roomBroadcastMessage !== ''" :style="{cursor:selectedCursor}" class="notificationAnnouncement" style="margin-top:0rem;background-color:#ADADAD" @[itemNeedsAcknowledgement]="confirmInstruction">
      <b-col cols="2" style="padding-left:0;">
        <img :class="newRoomBroadcastMessage ? 'blink-1' : ''" width="60px" src="~/assets/images/RoomBroadcastNotification.png"><img style="display:none;" src="~/assets/images/info_icon.svg">
      </b-col>
      <b-col cols="10">
        <div>
          <small>{{ roomBroadcastMessageFrom }} To Your Room • {{ roomBroadcastMessageTime }}</small>
          <classroom-broadcast-history
            ref="broadcastHistoryComponent"
            :incident-id="incidentId"
            :bag-guid="barcode"
            :show-all-broadcasts="false"
            :show-room-broadcasts="true"
          />
          <i v-if="roomBroadcastInstruction && roomBroadcastAcknowledgeRequired && !updatingAcknowledged" class="fa fa-check-circle fa-lg pull-right clickable blink-1" @click="confirmInstruction" />
          <img v-show="roomBroadcastInstruction && updatingAcknowledged && roomBroadcastAcknowledgeRequired" style="height:1rem;" class="pull-right" src="~/assets/images/5.gif">
          <i v-if="roomBroadcastInstruction && !roomBroadcastAcknowledgeRequired" class="fa fa-check-circle fa-lg pull-right instruction-completed" />
        </div>
        <div id="roomBroadcastMessage" class="" role="alert">
          {{ roomBroadcastMessage }}
        </div>
        <div id="broadcastMessageTimestamp" hidden>
          {{ roomBroadcastMessageTime }}
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ClassroomBroadcastHistory from '~/components/incident/ClassroomBroadcastHistory.vue'
const moment = require('moment')
// import { Store } from 'vuex'

// , mapState
export default {
  name: 'BroadcastMessage',
  // props: ['broadcastMessage'],
  components: {
    ClassroomBroadcastHistory
  },
  data () {
    return {
      visibilityChange: '',
      timer: null,
      timerDelay: 4000,
      timerCount: 0,
      barcode: null,
      destroyed: false,
      newBroadcastMessage: false,
      newRoomBroadcastMessage: false,
      updatingAcknowledged: false
    }
  },
  watch: {
    broadcastMessage () {
      this.newBroadcastMessage = true
      try {
        if (window.navigator) {
          window.navigator.vibrate(300)
        }
      } catch (error) {
        console.log('Unable to vibrate')
        console.log(error)
      }
      setTimeout(() => {
        this.newBroadcastMessage = false
      }, 15000)
    },
    roomBroadcastMessage (newRoomBroadcastMessage) {
      this.newRoomBroadcastMessage = true
      try {
        if (window.navigator) {
          window.navigator.vibrate(300)
        }
      } catch (error) {
        console.log('Unable to vibrate')
        console.log(error)
      }
      setTimeout(() => {
        this.newRoomBroadcastMessage = false
      }, 15000)
    }
  },
  mounted () {
    this.barcode = this.$route.params.barcode
    this.setVisibilityChange()
    this.setRepeatedTimer()
  },
  beforeDestroy () {
    this.destroyed = true
  },
  computed: {
    ...mapGetters(
      {
        broadcastMessage: 'BroadcastMessage/getBroadcastMessage',
        broadcastMessageDate: 'BroadcastMessage/getBroadcastMessageDate',
        broadcastMessageFrom: 'BroadcastMessage/getBroadcastMessageFrom',
        roomBroadcastMessage: 'BroadcastMessage/getRoomBroadcastMessage',
        incidentInstructions: 'BroadcastMessage/getIncidentInstructions',
        roomBroadcastMessageDate: 'BroadcastMessage/getRoomBroadcastMessageDate',
        roomBroadcastMessageFrom: 'BroadcastMessage/getRoomBroadcastMessageFrom',
        incidentId: 'BroadcastMessage/getIncidentId'
      }
    ),
    selectedCursor () {
      return this.itemNeedsAcknowledgement ? 'pointer' : ''
    },
    itemNeedsAcknowledgement () {
      return (this.roomBroadcastInstruction && this.roomBroadcastAcknowledgeRequired && !this.updatingAcknowledged) ? 'click' : null
    },
    roomBroadcastInstruction () {
      if (this.incidentInstructions == null) {
        return null
      }
      const matchingInstruction = this.incidentInstructions?.find(i => i.instruction === this.roomBroadcastMessage)
      if (matchingInstruction) {
        return matchingInstruction
      } else {
        return null
      }
    },
    roomBroadcastAcknowledgeRequired () {
      return this.roomBroadcastInstruction != null && this.roomBroadcastInstruction.instructionStatus === 0
    },
    currentInstructionId () {
      if (this.incidentInstructions == null) {
        return null
      }
      const matchingInstruction = this.incidentInstructions?.find(i => i.instruction === this.roomBroadcastMessage)
      if (matchingInstruction) {
        return matchingInstruction.id
      } else {
        return null
      }
    },
    broadcastMessageTime () {
      return this.broadcastMessageDate == null ? '' : moment(this.broadcastMessageDate).format('h:mm:ss a')
    },
    roomBroadcastMessageTime () {
      return this.roomBroadcastMessageDate == null ? '' : moment(this.roomBroadcastMessageDate).format('h:mm:ss a')
    }
    /*
    ...mapState({
      broadcastMessage: (state) => {
        // return 'test'
        return state.broadcastMessage
      }
    })
    */
  },
  methods: {
    ...mapMutations({ getBroadcastMessage: 'BroadcastMessage/get' }),
    isPageVisible () {
      return !(document[this.hidden])
    },
    isSetupPage () {
      if (this.$route?.name === 'redbag-barcode-setup') {
        return true
      }
      return false
    },
    setRepeatedTimer () {
      setTimeout(function () {
        if (this.destroyed) {
          return
        }
        this.timerCount += 1

        // Set delay so we can use backoff
        this.setTimerDelay()

        // Check status of Bag
        if (this.isPageVisible() && !this.isSetupPage()) {
          this.$store.dispatch('BroadcastMessage/get', this.$route.params.barcode)
        } else {
          console.log('Stopping the request because we\'re not looking at the app or we\'re on setup page')
        }

        // Call our timer again
        this.setRepeatedTimer()
      }.bind(this), this.timerDelay)
    },
    setTimerDelay () {
      // this.timerDelay = this.timerDelay + 1000
      if (this.$route.params.barcode == null) {
        this.timerDelay = 60000
      } else if (this.timerCount >= 1000) {
        this.timerDelay = 10000
      } else if (this.timerCount >= 100) {
        this.timerDelay = 5000
      } else if (this.timerCount >= 0) {
        this.timerDelay = 2000
      } else {
        this.timerDelay = 2000
      }
    },
    gotoInstruction () {
      this.$nuxt.$emit('goto-instruction', this.currentInstructionId)
    },
    async confirmInstruction () {
      this.updatingAcknowledged = true
      this.$nuxt.$emit('confirm-instruction', this.currentInstructionId)
      await new Promise(resolve => setTimeout(resolve, 5000))
      this.updatingAcknowledged = false
    },
    setVisibilityChange () {
      // https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
      if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
        this.hidden = 'hidden'
        this.visibilityChange = 'visibilitychange'
      } else if (typeof document.msHidden !== 'undefined') {
        this.hidden = 'msHidden'
        this.visibilityChange = 'msvisibilitychange'
      } else if (typeof document.webkitHidden !== 'undefined') {
        this.hidden = 'webkitHidden'
        this.visibilityChange = 'webkitvisibilitychange'
      }
      // alert(`Hidden: ${this.hidden}, changetype: ${this.visibilityChange}`)
    }
  }
}
</script>
<style scoped>
.blink-1 {
  -webkit-animation: blink-1 2s infinite both;
  animation: blink-1 2s infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-8-23 20:5:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation blink-1
 * ----------------------------------------
 */
@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

#roomBroadcastMessage {
    background-color: #ADADAD;
    border-radius: 1rem 1rem 1rem 0rem;
    padding: 0rem;
    color: white;
    font-weight:700;
}
/deep/ #broadcastMessage {
    background-color: #6588B1;
    border-radius: 1rem 1rem 1rem 0rem;
    padding: 0rem;
    color:white;
    font-weight:700;
}
.instruction-completed{
  color:#8dd896;
  /* color: #c3e6cb; */
}
.clickable {
  cursor: pointer;
}
.notificationAnnouncement{
  padding: 0.5rem;
  margin-bottom:0;
  padding-bottom: 0;
  text-align: left;
}
.sticky-top {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0px;
  z-index: 1040;
}
</style>
