export const state = () => ({
  activeIncident: false,
  broadcastMessage: '',
  broadcastedAt: null,
  broadcastedBy: null,
  roomBroadcastMessage: '',
  roomBroadcastedAt: null,
  roomBroadcastedBy: null,
  incidentInstructions: [],
  incidentId: null,
  incidentUpdateReceivedOn: null,
  bagName: null,
  hasChat: false
})

export const mutations = {
  toggle (state) {
    state.activeIncident = !state.activeIncident
  },
  setBroadcastMessage (state, data) {
    if (data?.incident?.broadcast?.broadcastMessage && data.incident.broadcast.broadcastMessage !== '') {
      state.incidentInstructions = data.incident.instructionsList
      state.incidentId = data.incident.incidentId
      state.incidentUpdateReceivedOn = new Date()
      state.broadcastMessage = data.incident.broadcast.broadcastMessage
      state.broadcastedAt = data.incident.broadcast.broadcastedAt
      state.broadcastedBy = data.incident.broadcast.sentByName
      state.bagName = data.name
      state.hasChat = data.hasChat
      state.activeIncident = true
    } else {
      state.activeIncident = false
      state.broadcastMessage = null
      state.broadcastedAt = null
      state.broadcastedBy = null
      state.roomBroadcastMessage = null
      state.roomBroadcastedAt = null
      state.roomBroadcastedBy = null
      state.incidentId = null
      state.bagName = null
      state.incidentUpdateReceivedOn = null
      state.incidentInstructions = []
      state.hasChat = false
    }
    if (data?.incident?.roomBroadcast?.broadcastMessage && data.incident.roomBroadcast.broadcastMessage !== '') {
      state.roomBroadcastMessage = data.incident.roomBroadcast.broadcastMessage
      state.roomBroadcastedAt = data.incident.roomBroadcast.broadcastedAt
      state.roomBroadcastedBy = data.incident.roomBroadcast.sentByName
    } else {
      state.roomBroadcastMessage = null
      state.roomBroadcastedAt = null
      state.roomBroadcastedBy = null
    }
  }
}

export const getters = {
  getIncidentUpdateReceivedOn (state) {
    return state.incidentUpdateReceivedOn
  },
  getIncidentInstructions (state) {
    return state.incidentInstructions
  },
  getIncidentId (state) {
    return state.incidentId
  },
  getActiveIncidentState (state) {
    return state.activeIncident
  },
  getBroadcastMessage (state) {
    return state.broadcastMessage
  },
  getBroadcastMessageDate (state) {
    return state.broadcastedAt
  },
  getBroadcastMessageFrom (state) {
    return state.broadcastedBy
  },
  getRoomBroadcastMessage (state) {
    return state.roomBroadcastMessage
  },
  getRoomBroadcastMessageDate (state) {
    return state.roomBroadcastedAt
  },
  getRoomBroadcastMessageFrom (state) {
    return state.roomBroadcastedBy
  },
  getBagName (state) {
    return state.bagName
  },
  getBagHasChat (state) {
    return state.hasChat
  }
}
export const actions = {
  async get ({ commit }, bagGuid) {
    if (!process.env.NUXT_ENV_CLOUD_API_URL.includes('localhostNOT') && bagGuid && bagGuid !== '') {
      // bagGuid = '8e262f1e-920c-4bcf-a89c-2defb101601f'
      const result = await this.$axios.get(`${process.env.NUXT_ENV_CLOUD_API_URL}/WebhookTest?RedBagGuid=${bagGuid}`, { validateStatus: false }).catch((error) => {
        console.error(error)
      })
      if (result && result.status === 200) {
        commit('setBroadcastMessage', result.data)
      } else if (result && result.status === 404) {
        console.log('No bag with that ID exists yet')
      }
    } else {
      console.log('Broadcast message updating, but no API detected')
      // commit('setBroadcastMessage', { broadcastMessage: 'Shelter in place.  Next update, 15 minutes.' })
    }
  },
  async show ({ commit }, params) {
    await this.$axios.get(`cars/${params.car_id}`)
      .then((res) => {
        if (res.status === 200) {
          commit('setCar', res.data)
        }
      })
  },
  async set ({ commit }, car) {
    await commit('set', car)
  }
}
